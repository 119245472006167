@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf") format("truetype");
}

html{ 
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Raleway', sans-serif;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  scroll-behavior: smooth;
}

#root {
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(#001400, #038100);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#001400, #038100);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#001400, #038100);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#001400, #038100);
    /* Standard syntax (must be last) */
    background-repeat: no-repeat;
    color: white;
}

h1,
h2,h3, h4, h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
}

p{
  font-family: 'Raleway', sans-serif;
}

#section-1 {
  background-image: url("./assets/images/section-1.png");
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: normal;
  background-size: cover;
}


.blur-ball-left {
  position: absolute;
  width: 1203px;
  height: 1203px;
  left: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}

.blur-ball-right {
  position: absolute;
  width: 1203px;
  height: 1203px;
  right: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}

